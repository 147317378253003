    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    
    .auth-logo {
        width: 60px;
    }
    
    .lg-card-image {
        background-size: 320px;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 280px;
    }
    
    .centered-container {
        /* height: 200px; */
        position: relative;
        padding: 20px 20px 100px 20px;
    }
    
    .centered-inner {
        margin: 0;
        position: absolute;
        top: 150%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    
    .copy-trader-info {
        height: 360px;
    }
    
    .symbol-card-chart {
        height: 100px;
        position: relative;
    }
    
    .select-symbol-header {
        display: inline-block;
    }
    
    .symbol-group {
        width: 300px;
        display: flex;
        justify-content: space-between;
    }
    
    .wallet-card {
        background-color: rgb(235, 242, 243);
        padding: 10px 12px;
        border-radius: 4px;
    }
    
    .wallet-card-title {
        font-size: 15px;
    }
    
    .wallet-card-bal {
        font-weight: bold;
        font-size: 24px;
    }
    
    .wallet-switch {
        font-size: 13px;
        display: inline-block;
        padding: 3px 10px;
        border-radius: 10px;
        cursor: pointer;
    }
    
    .wallet-active {
        font-size: 13px;
        display: inline-block;
        padding: 3px 10px;
        border-radius: 10px;
        cursor: pointer;
    }
    
    @media screen and (max-width: 992px) {
        .centered-inner {
            top: 150%;
        }
        .copy-trader-info {
            height: 290px;
        }
        .select-symbol-header {
            display: none;
        }
    }
    /* On screens that are 600px or less, set the background color to olive */
    
    @media screen and (max-width: 600px) {
        .centered-inner {
            top: 100%;
        }
        .copy-trader-info {
            height: 290px;
        }
        .select-symbol-header {
            display: none;
        }
    }